/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    code: "code",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Software Development Kit (SDK) gives you tools to build integrations to Signatu using\nthe ", React.createElement(_components.a, {
    href: "https://reactjs.org"
  }, "React UI library"), ". React 16.8 or higher is required."), "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/react/styles"
  }, "Theming"), " for information about how to style the components."), "\n", React.createElement(_components.p, null, "See ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/react/context"
  }, "Signatu Context"), " for information about how to provide a consent context."), "\n", React.createElement(_components.p, null, "This page describes how to use the React component. If you\nwant to integrate the self-contained Javascript tag instead, refer to ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/react/cookie-banner"
  }, "Javascript tag documentation"), "."), "\n", React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "The Cookie Banner is a special kind of ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/react/consent"
  }, "Consent"), " component and behaves\nmuch like the other consent components."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "accessToken"), " provided to the component through the ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/react/context"
  }, "context"), " must\nhave both ", React.createElement(_components.code, null, "consent"), " and ", React.createElement(_components.code, null, "dataprocessing:read"), " permissions, since the actual cookie banner\nspecification is provided from a Data Processing Group."), "\n", React.createElement(_components.p, null, "We strongly recommend using short-lived tokens in the web site. If you choose\nto use long-lived tokens, make sure to be extra diligent in ensuring that the scopes\nprovided does not include write permissions (except for ", React.createElement(_components.code, null, "consent"), "). Also see\n", React.createElement(_components.a, {
    href: "/docs/api/v1/security"
  }, "security"), " for more information."), "\n", React.createElement(_components.h2, {
    id: "props",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#props",
    "aria-label": "props permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Props"), "\n", React.createElement(_components.p, null, "The banner accepts the following props:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "className"), " - (optional) add the className to the root element of the banner."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "endPoints"), " - (optional) APIEndPoints. See below."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "consentTarget"), " - (optional) ConsentTarget. See below."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "language"), " - (optional) ISO 639-1 code for the banner language. If not specified, the language will be autodetected from browser settings."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "options"), "- (required) CookieBannerOptions. See below."), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "config"), " and ", React.createElement(_components.code, null, "consentTarget"), " are optional only if you provide them via a ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk/react/context"
  }, "Consent Context"), "."), "\n", React.createElement(_components.h3, {
    id: "apiendpoints",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#apiendpoints",
    "aria-label": "apiendpoints permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "APIEndPoints"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "consentAPI"), ": string - (optional) the URL to the Consent API to use (e.g., if using a proxy)."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "trackerdetectAPI"), ": string - (optional) the URL to the Trackerdetect API"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "dataprocessingAPI"), ": string - (optional) the URL to the Data Processing API"), "\n"), "\n", React.createElement(_components.h3, {
    id: "cookiebanneroptions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cookiebanneroptions",
    "aria-label": "cookiebanneroptions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CookieBannerOptions"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "groupId"), " - (required) the Data Processing Group id for the cookie banner. This is typically automatically generated from a Trackerdetect scan."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "variant"), "- (optional) CookieBannerVariant. The variant of the banner ( see below )."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "privacyPolicyUrl"), " - (optional) URL to the Privacy Policy. If provided, links to the Policy will be included in the banner."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "position"), "- (optional) CookieBannerPosition. The position of the banner. See below."), "\n"), "\n", React.createElement(_components.h3, {
    id: "cookiebannervariant",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cookiebannervariant",
    "aria-label": "cookiebannervariant permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CookieBannerVariant"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "gdpr"), " - (default) use the GDPR variant of the banner."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "ekom"), " - use the Norwegian EKOM law variant of the banner."), "\n"), "\n", React.createElement(_components.h3, {
    id: "cookiebannerposition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cookiebannerposition",
    "aria-label": "cookiebannerposition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CookieBannerPosition"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "top"), " - anchor the banner on the top of the screen"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "center"), " - anchor the banner on the center of the screen"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "bottom"), " - anchor the banner on the bottom of the screen"), "\n"), "\n", React.createElement(_components.h3, {
    id: "typescript",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#typescript",
    "aria-label": "typescript permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Typescript"), "\n", React.createElement(_components.p, null, "Typescript typings are included with the library."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
